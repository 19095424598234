<template>
  <div class="way">
    <div class="way-title"></div>
    <div class="way-code">
      <div class="way-code-main">
        <!-- <div class="way-code-main-code" id="qrcode"></div> -->
        <qrcode-vue :value="qrcodeText" :size="113" render-as="svg"> </qrcode-vue>
      </div>
    </div>
    <div class="way-desc">转发此链接或面对面扫码邀请</div>
    <div class="way-action">
      <div class="way-action-invite" @click="onClickInvite"></div>
      <div class="way-action-share" @click="onClickShare"></div>
    </div>
    <div class="way-red"></div>
    <van-popup
      v-model="picShare.show"
      round
      :style="{ width: '80%', borderRadius: '10px' }"
      closeable
    >
      <div class="way-share" v-if="picShare.show">
        <div class="way-share-content">
          <div class="way-share-content-title">分享截图给好友</div>
          <div class="way-share-content-img" ref="shareImg">
            <div class="way-share-content-img-bg">
              <img src="@/assets/img/invite/pic_share_bg.png" class="bg-img" />
            </div>
            <div class="way-share-content-img-content">
              <div class="code">
                <div class="code_main">
                  <!-- <div class="code-main-code" id="share_qrcode"></div> -->
                  <qrcode-vue :value="qrcodeText" :size="55" render-as="svg"></qrcode-vue>
                </div>
              </div>
              <div class="desc">扫我即可免费登记试用</div>
              <div class="rule">
                <div class="rule_title">
                  <img src="@/assets/img/invite/rule_title.png" class="rule_title_img" />
                </div>
                <div class="rule_content">
                  亲爱的用户，为了感谢大家一直以来的关注与参与，我们推出云算房计价礼品兑换活动，您可以使用积攒的乡农网200元进行兑换，每个月每人每个档位的礼品只限兑换一种。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="way-share-footer">
          <div
            class="way-share-footer-item"
            style="border-right: 1px solid #b1b1b1"
            @click="onClickCancel"
          >
            取消
          </div>
          <div class="way-share-footer-item" @click="onClickSave">保存本地</div>
        </div>
      </div>
    </van-popup>
    <van-overlay
      :show="overlayShow"
      :z-index="9999"
      :lock-scroll="true"
      @click="overlayShow = false"
    >
      <div class="overlay_content">
        <img class="overlay_content_img" :src="img" alt="" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import QrcodeVue from "qrcode.vue";
import Vue from "vue";
import { Notify } from "vant";
export default Vue.extend({
  components: {
    QrcodeVue,
  },
  data() {
    return {
      shareUrl: "",
      picShare: {
        show: false,
      },
      overlayShow: false,
      img: require("@/assets/img/invite/overlay_wx.png"),
      qrcodeText: "",
    };
  },
  mounted() {
    this.shareUrl = process.env.VUE_APP_INVITE_URL + `/invite`;
    this.qrcodeText =
      this.shareUrl +
      `?inviterMobile=${this.$store.getters.currentLoginMobile}&invitationMethod=0`;
    // this.$nextTick(() => {
    //   new qrCode(document.getElementById("qrcode"), {
    //     text:
    //       this.shareUrl +
    //       `?inviterMobile=${this.$store.getters.currentLoginMobile}&invitationMethod=0`,
    //     width: 113,
    //     height: 113,
    //     colorDark: "#000000",
    //     colorLight: "#ffffff",
    //     correctLevel: qrCode.CorrectLevel.H,
    //   });
    // });
  },
  methods: {
    onClickShare() {
      this.picShare.show = true;
      // this.$nextTick(() => {
      //   new qrCode(document.getElementById("share_qrcode"), {
      //     text:
      //       this.shareUrl +
      //       `?inviterMobile=${this.$store.getters.currentLoginMobile}&invitationMethod=0`,
      //     width: 45,
      //     height: 45,
      //     colorDark: "#000000",
      //     colorLight: "#ffffff",
      //     correctLevel: qrCode.CorrectLevel.H,
      //   });
      // });
    },
    onClickCancel() {
      this.picShare.show = false;
    },
    onClickSave() {
      html2canvas(this.$refs.shareImg, {
        imageTimeout: 15000, //newline
        useCORS: true,
      }).then((canvas) => {
        let imageUrl = canvas.toDataURL("image/png"); // 将canvas转成base64图片格式
        const aLink = document.createElement("a");
        const blob = this.base64ToBlob(imageUrl);
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = "云算房邀请.png";
        aLink.href = URL.createObjectURL(blob);
        aLink.dispatchEvent(
          new MouseEvent("click", { bubbles: true, cancelable: true, view: window })
        );
      });
      // this.picShare.show = false;
    },
    base64ToBlob(code) {
      const parts = code.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    onClickInvite() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (/MicroMessenger/i.test(ua)) {
        this.overlayShow = true;
      } else {
        // 创建textarea标签
        const textarea = document.createElement("textarea");
        // 设置相关属性 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
        textarea.readOnly = true;
        textarea.style.position = "absolute";
        textarea.style.top = "-99999px";
        // 把目标内容赋值给它的value属性
        textarea.value =
          this.shareUrl +
          `?inviterMobile=${this.$store.getters.currentLoginMobile}&invitationMethod=1`;
        // 插入到页面
        document.body.appendChild(textarea);
        // 调用onselect()方法
        textarea.select();
        // 把目标内容复制进剪贴板, 该API会返回一个Boolean
        const res = document.execCommand("Copy");
        // 移除textarea标签
        document.body.removeChild(textarea);
        Notify({ type: "success", message: "复制成功" });
      }
    },
  },
});
</script>
<style lang="less" scoped>
.way {
  background-color: #fff;
  padding-bottom: 40px;
  margin: 0 20px;
  border-radius: 10px;
  padding-top: 34px;
  &-title {
    background-image: url("../../../assets/img/invite/way_title.png");
    background-size: 100% 100%;
    width: 130px;
    height: 20px;
    margin: 0 auto;
  }
  &-code {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    &-main {
      background: rgba(255, 255, 255, 0.39);
      border-radius: 6px;
      border: 4px solid #f5bb59;
      font-size: 0;
    }
  }
  &-desc {
    font-weight: 400;
    font-size: 15px;
    color: #333333;
    text-align: center;
    margin-top: 11px;
  }
  &-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
    &-invite {
      width: 280px;
      height: 50px;
      background-image: url("../../../assets/img/invite/invite.png");
      background-size: 100% 100%;
    }
    &-share {
      margin-top: 10px;
      width: 280px;
      height: 50px;
      background-image: url("../../../assets/img/invite/share.png");
      background-size: 100% 100%;
    }
  }
  &-red {
    margin: 0 auto;
    margin-top: 20px;
    width: 280px;
    height: 114px;
    background-image: url("../../../assets/img/invite/red_packet.png");
    background-size: 100% 100%;
  }
  &-share {
    &-content {
      font-size: 0px;
      &-title {
        font-size: 17px;
        color: #737373;
        text-align: center;
        margin-top: 20px;
      }
      &-img {
        margin: 20px auto;
        width: 125px;
        position: relative;
        padding-bottom: 8px;
        &-bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          .bg-img {
            width: 125px;
            height: 100%;
          }
        }
        &-content {
          position: relative;
          z-index: 2;
        }
        .code {
          display: flex;
          justify-content: center;
          padding-top: 145px;
          &_main {
            background: #fff;
            border-radius: 3px;
            border: 2px solid #f5bb59;
            padding: 2px;
            font-size: 0;
          }
        }
        .desc {
          color: #fff;
          font-size: 5px;
          text-align: center;
          margin-top: 4px;
        }
        .rule {
          background-color: #fff;
          border-radius: 4px;
          margin: 6px;
          padding: 6px;
          &_title {
            font-size: 0px;
            display: flex;
            justify-content: center;
            &_img {
              width: 52px;
              height: 8px;
            }
          }
          &_content {
            margin-top: 4px;
            font-weight: 400;
            font-size: 4px;
            color: #391916;
          }
        }
      }
    }
    &-footer {
      display: flex;
      border-top: 1px solid #b1b1b1;
      &-item {
        height: 40px;
        flex: 1;
        font-size: 14px;
        color: #4576fa;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.overlay_content {
  &_img {
    width: 240px;
    float: right;
    margin-top: 14px;
    margin-right: 32px;
  }
}
</style>
