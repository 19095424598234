<template>
  <div class="record">
    <div class="record-title"></div>
    <div class="record-content">
      <div class="record-content-title">
        <div class="record-content-title-item">用户</div>
        <div class="record-content-title-item">邀请记录</div>
      </div>
      <div class="list">
        <div class="list-content" ref="refInvite" v-if="inviteList.length > 0">
          <div>
            <div class="list-item" v-for="(item, index) in inviteList" :key="index">
              <div class="list-item-user">{{ item.inviteeMobile }}</div>
              <div class="list-item-time">{{ item.recDateStr }}</div>
            </div>
          </div>
        </div>
        <div class="list-empty" v-else>
          <div class="list-empty-text">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecommendList } from "@/api/invite";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      title: "邀请记录",
      inviteList: [],
      autoScrollTimeout: null,
      autoScrollInterval: null,
      inviterMobile: "",
      invitationMethod: "1",
    };
  },
  methods: {
    GetRecommendList() {
      getRecommendList(
        this.$store.getters.currentLoginMobile,
        this.$store.getters.inviteToken
      )
        .then((res) => {
          this.inviteList = (res ?? []).map((item) => {
            return {
              inviteeMobile: item.inviteeMobile,
              recDateStr: item.recDateStr,
            };
          });
          // if (this.inviterMobile !== this.$store.getters.currentLoginMobile) {
          //   let currentUrl = window.location.href;
          //   if (currentUrl.indexOf("?") != -1) {
          //     let newUrl = currentUrl.substr(0, currentUrl.indexOf("?") + 1);
          //     let info = decodeURIComponent(
          //       JSON.stringify({
          //         inviterMobile: this.$store.getters.currentLoginMobile,
          //         invitationMethod: 1,
          //       })
          //     );
          //     window.history.replaceState(null, "", newUrl + `?info=${info}`);
          //   }
          // }
          this.$nextTick(() => {
            this.AutoScroll();
          });
        })
        .catch((error) => {
          if (
            error?.status === 400 ||
            error?.status === 401 ||
            error?.status === 403 ||
            error?.data?.status === 400 ||
            error?.data?.status === 401 ||
            error?.data?.status === 403
          ) {
            if (this.inviterMobile !== this.$store.getters.currentLoginMobile) {
              this.$router.replace({
                path: `/invite?inviterMobile=${this.inviterMobile}&invitationMethod=${this.invitationMethod}`,
              });
            } else {
              this.$router.replace({
                path: `/invite`,
              });
            }
            localStorage.removeItem("inviteToken");
            localStorage.removeItem("currentLoginMobile");
            this.$store.commit("SET_INVITE_TOKEN", "");
            this.$store.commit("SET_CURRENT_LOGIN_MOBILE", "");
          }
        });
    },
    AutoScroll() {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
      }
      if (this.autoScrollTimeout) {
        clearTimeout(this.autoScrollTimeout);
      }
      console.log(this.$refs.refInvite.scrollTop);
      this.$nextTick(() => {
        this.autoScrollInterval = setInterval(() => {
          this.autoScrollTimeout = setTimeout(() => {
            this.$refs.refInvite.scrollTop = this.$refs.refInvite.scrollTop + 1;
            if (
              this.$refs.refInvite.clientHeight + this.$refs.refInvite.scrollTop >=
              this.$refs.refInvite.scrollHeight
            ) {
              this.$refs.refInvite.scrollTop = 0;
            }
          }, 1000);
        }, 60);
      });
    },
  },
  mounted() {
    if (this.$route.query?.info) {
      let info = (this.$route.query?.info ?? "").split("_");
      if (info.length > 1) {
        this.inviterMobile = (info?.[0] ?? "").replace("inviterMobile,", "");
        this.invitationMethod = "1";
        if (
          (this.$store.getters.inviteToken ?? "") !== "" &&
          this.$store.getters.currentLoginMobile !== this.inviterMobile
        ) {
          localStorage.removeItem("inviteToken");
          localStorage.removeItem("currentLoginMobile");
          this.$store.commit("SET_INVITE_TOKEN", "");
          this.$store.commit("SET_CURRENT_LOGIN_MOBILE", "");
          this.$router.replace({
            path: `/invite?inviterMobile=${this.inviterMobile}&invitationMethod=${this.invitationMethod}`,
          });
        } else {
          this.GetRecommendList();
        }
      }
    } else {
      this.GetRecommendList();
    }
  },
  beforeDestroy() {
    if (this.autoScrollInterval) {
      clearInterval(this.autoScrollInterval);
    }
    if (this.autoScrollTimeout) {
      clearTimeout(this.autoScrollTimeout);
    }
  },
});
</script>
<style lang="less" scoped>
.record {
  background-color: #fff;
  padding-bottom: 40px;
  margin: 0 20px;
  border-radius: 10px;
  padding-top: 34px;
  &-title {
    background-image: url("../../../assets/img/invite/record_title.png");
    background-size: 100% 100%;
    width: 130px;
    height: 20px;
    margin: 0 auto;
  }
  &-content {
    margin: 0 20px;
    margin-top: 20px;
    &-title {
      display: flex;
      align-items: center;
      background-color: #ededec;
      line-height: 40px;
      &-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #391916;
      }
    }
    .list {
      &-content {
        height: 250px !important;
        overflow: hidden;
      }
      &-empty {
        height: 250px !important;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999999;
        font-size: 18px;
      }
      &-item {
        display: flex;
        align-items: center;
        line-height: 40px;
        &-user {
          flex: 1;
          font-size: 14px;
          color: #391916;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-time {
          flex: 1;
          font-size: 14px;
          color: #391916;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
