<template>
  <div class="invite" style="overflow: hidden">
    <div class="invite-bg">
      <img src="@/assets/img/invite/invite_bg.png" class="invite-bg-img" />
    </div>
    <div class="invite-withdraw" @click="$router.push('/withdraw')">
      <div>去</div>
      <div>提</div>
      <div>现</div>
    </div>
    <div class="content">
      <div class="invite-way">
        <InviteWay></InviteWay>
      </div>
      <div class="invite-record">
        <InviteRecord></InviteRecord>
      </div>
      <div class="activity-rule">
        <ActivityRule></ActivityRule>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import InviteRecord from "./components/invite-record.vue";
import InviteWay from "./components/invite-way.vue";
import ActivityRule from "./components/activity-rule.vue";
export default Vue.extend({
  components: { InviteRecord, ActivityRule, InviteWay },
  data() {
    return {};
  },
  mounted() {
    document.title = "公测赢福利  纵享三重礼";
  },
});
</script>
<style lang="less" scoped>
.invite {
  width: 100%;
  position: relative;
  &-bg {
    &-img {
      width: 100%;
    }
  }
  &-withdraw {
    width: 30px;
    height: 100px;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 180px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    color: #fb5b03;
  }
  .content {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    margin-top: 410px;
    z-index: 20;
    .invite-record {
      margin-top: 20px;
    }
    .activity-rule {
      margin-top: 20px;
    }
  }
}
</style>
