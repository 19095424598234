<template>
  <div>
    <div class="rule">
      <div class="rule-title"></div>
      <div class="rule-content">
        <div class="rule-content-one">
          亲爱的用户，为了感谢大家一直以来的关注与
          参与，我们推出云算房计价礼品兑换活动，您可以使用积攒的乡农网200元进行兑换，每个
          月每人每个档位的礼品只限兑换一种。
        </div>
        <div class="rule-content-two">
          为了尽量保证大家都有机会换到礼品，每个月
          我们都会去更新礼品的数量，您可以定期来看看有没有您想要的礼品。
        </div>
        <div class="rule-content-time">活动时间：截止至2024年10月31日</div>
      </div>
    </div>
    <div class="shadow">
      <div class="shadow-one"></div>
      <div class="shadow-two"></div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default Vue.extend({});
</script>
<style lang="less" scoped>
.rule {
  background-color: #fff;
  padding-bottom: 40px;
  margin: 0 20px;
  border-radius: 10px;
  padding-top: 34px;
  &-title {
    background-image: url(../../../assets/img/invite/rule_title.png);
    background-size: 100% 100%;
    width: 130px;
    height: 20px;
    margin: 0 auto;
  }
  &-content {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #391916;
    padding: 0 30px;
    line-height: 24px;
    &-two {
      margin-top: 20px;
    }
    &-time {
      margin-top: 20px;
    }
  }
}
.shadow {
  margin: 0 60px;
  position: relative;
  &-one {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    height: 20px;
    background-color: rgba(256, 256, 256, 0.4);
    border-radius: 0 0 10px 10px;
  }
  &-two {
    margin: 0 20px;
    height: 36px;
    background-color: rgba(256, 256, 256, 0.4);
    border-radius: 0 0 10px 10px;
  }
}
</style>
